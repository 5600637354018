@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Nunito+Sans&display=swap');

body,html {
    font-family: 'Noto Sans', 'Nunito Sans';

}

li {
    list-style-type: none
}

.terminal-output {
    padding: 1px;
    border-bottom: 0.5px white solid;
}

.terminal-output.warn {
    color: orange;
}

.terminal-output.error {
    color: red;
}
.homePageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    
        background-image:
            radial-gradient(at 47% 33%, hsl(162.00, 77%, 40%) 0, transparent 59%),
            radial-gradient(at 82% 65%, hsl(198.00, 100%, 50%) 0, transparent 55%);
    
}

.formWrapper {
    background: #282a36;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    font-family: 'Noto Sans', 'Nunito Sans';
    backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(255, 255, 255, 0.37);
        border-radius: 12px;
        border: 1px solid rgba(209, 213, 219, 0.3);
}

footer {
    position: fixed;
    bottom: 0;
}

footer a {
    color: #4aee88;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    font-family: 'Noto Sans', 'Nunito Sans';
}

.mainLabel {
    margin-bottom: 20px;
    margin-top: 0;
}

.homePageLogo {
    height: 80px;
    margin-bottom: 30px;
}

.inputBox {
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    margin-bottom: 14px;
    background: #eee;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Noto Sans', 'Nunito Sans'
}

.btn {
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Noto Sans', 'Nunito Sans'
}

.joinBtn,
.leaveBtn {
    background: #4aed88;
    width: 100px;
    margin-left: auto;
    font-family: 'Noto Sans', 'Nunito Sans'
}

.joinBtn:hover,
.leaveBtn:hover {
    background: #2b824c;
}

.createInfo {
    margin: 0 auto;
    margin-top: 20px;
    font-family: 'Noto Sans', 'Nunito Sans';
}

.createNewBtn {
    color: #2441e2;
    text-decoration: none;
    border-bottom: 1px solid #4aed88;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.createNewBtn:hover,
footer a:hover {
    color: #368654;
    border-color: #368654;
    font-family: 'Noto Sans', 'Nunito Sans';
}

.mainWrap {
    display: grid;
    grid-template-columns: 230px 1fr;
    
}

.aside {
    background: #1c1e29;
    padding: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
}
.asideInner {
    flex: 1;
}

.clientsList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.client {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
}
.userName {
    margin-top: 10px;
    font-family: 'Noto Sans', 'Nunito Sans';
}

.logo {
    border-bottom: 1px solid #424242;
    padding-bottom: 10px;
}

.logoImage {
    height: 60px;
}

.leaveBtn {
    width: 100%;
    margin-top: 20px;
}
.CodeMirror {
    min-height: calc(95vh - 20px);
        font-size: 20px;
    line-height: 1.6;
    padding-top: 10px;
    }

@media screen and (min-width: 1387) {
    .EditorConsole {
        min-height: '427' !important;
    }
}

@media screen and (min-width: 900) {
    .CodeMirror {
        min-height: calc(95vh - 20px) !important;
    }
}

@media screen and (min-width: 600) {
    .CodeMirror {
        min-height: calc(92vh - 20px) !important;
    }
}

@media screen and (min-width: 480) {
    .CodeMirror {
        min-height: calc(90vh - 20px) !important;
    }
}